import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../layout"
import PagesHero from "../../pages"
import Section from "../../section"
import BoxCard from "../../boxCard"
import ParallaxContainer from "../../ParallaxContainer"

import Li from "../../Li"
import { colors } from "../../../utils/presets"

import { FormattedMessage, FormattedHTMLMessage } from "react-intl"
import PrimaryTitle from "../../PrimaryTitle"

import { Parallax } from "react-scroll-parallax"

/** @jsx jsx */
import { jsx } from "@emotion/core"

import map from "../../../images/map.svg"

const Trasportation = props => {
  const data = useStaticQuery(graphql`
    query {
      transporte: file(name: { eq: "transporte" }) {
        childImageSharp {
          fluid(maxWidth: 540, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      flota: file(name: { eq: "flota-vehiculars" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout location={props.location}>
      <PagesHero>
        <Section>
          <div className="has-text-centered mb-6">
            <PrimaryTitle textId={`Transportation`} />
            <FormattedMessage id="ts_titleText" />
          </div>
          <ParallaxContainer>
            <Parallax y={[-25, 25]} tagOuter="figure">
              <Img
                alt={`Transportation`}
                fluid={data.transporte.childImageSharp.fluid}
              />
            </Parallax>
          </ParallaxContainer>
          <section
            css={{
              position: `relative`,
              top: `-120px`,
            }}
          >
            <BoxCard>
              <FormattedHTMLMessage id={`ts_GetQuote`} />
              <br />
              <div css={{ position: `relative`, padding: `80px 80px 110px` }}>
                <div
                  css={{
                    position: `absolute`,
                    top: 0,
                    opacity: `0.4`,
                    left: 0,
                    right: 0,
                    textAlign: `center`,
                  }}
                >
                  <img width={500} alt={`mapa`} src={map} />
                </div>
                <div className="columns" css={{ marginTop: 45 }}>
                  <div className="column is-offset-one-quarter is-4">
                    {" "}
                    <ul>
                      <Li>Managua</Li>
                      <Li>Granada</Li>
                      <Li>León</Li>
                      <Li>Chinandega</Li>
                      <Li>Montelimar</Li>
                    </ul>
                  </div>
                  <div className="column ">
                    {" "}
                    <ul>
                      <Li>Estelí</Li>
                      <Li>Matagalpa</Li>
                      <Li>Jinotega</Li>
                      <Li>San Juan del Sur</Li>
                      <Li>Peñas Blancas</Li>
                    </ul>
                  </div>
                </div>
              </div>
            </BoxCard>
          </section>
        </Section>
        <section
          css={{
            position: `relative`,
            top: `-50px`,
          }}
        >
          <Section class={`gray-section`}>
            <div
              className="has-text-centered"
              css={{
                marginBottom: 40,
              }}
            >
              <PrimaryTitle textId="VehicleFleet" />
              <FormattedMessage id="FletQuote" />
            </div>
            <div className="columns">
              <div className="column">
                <Img
                  alt={`Flota Vehicular`}
                  fluid={data.flota.childImageSharp.fluid}
                />
              </div>
              <div
                className="column"
                css={{
                  display: `flex`,
                  alignItems: `center`,
                }}
              >
                <div
                  css={{
                    padding: `0 30px`,
                  }}
                >
                  <ul>
                    <Li>
                      <b>Hyundai H1:</b>
                      <br />
                      <FormattedMessage id="HyundaiH1" />
                    </Li>
                    <Li>
                      <b>Toyota Hiace:</b>
                      <br />
                      <FormattedMessage id="ToyotaHiace" />{" "}
                    </Li>
                    <Li>
                      <b>Toyota Coaster:</b>
                      <br />
                      <FormattedMessage id="ToyotaCoaster" />{" "}
                    </Li>
                    <Li>
                      <b>Marcopolo Senior:</b>
                      <br />
                      <FormattedMessage id="MarcoPoloSenior" />{" "}
                    </Li>
                    <Li>
                      <b>Marcopolo Scania:</b>
                      <br />
                      <FormattedMessage id="MarcoPoloScania" />
                    </Li>
                  </ul>
                </div>
              </div>
            </div>
          </Section>
        </section>
      </PagesHero>
    </Layout>
  )
}

export default Trasportation

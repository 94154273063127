import React from "react"
import Transportation from "../../components/Content/Transportation"
import Seo from "../../components/seo"

export default props => (
  <React.Fragment>
    <Seo
      title={`Transportation`}
      description={`We offers a unique and professional transportation service`}
    />

    <Transportation location={props.location} />
  </React.Fragment>
)
